import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import sampleImage from "../img/products/sample-image.jpg"
import { Link } from "gatsby"

export default function AboutUs() {
  const [productList, setproductList] = useState([
    "APPAREL",
    "BAGS",
    "DRINKWARE",
    "OFFICE UTILITY",
    "ACCESSORIES",
    "HOME UTILITIES",
    "GADGETS",
    "FITNESS & WELLBEING",
  ])
  return (
    <div>
      <Helmet>
      <title>Products - Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="Products -  Represent Corporate Giftings"
        />
        <meta name="description" content="Products" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/" />
        <meta property="og:description" content="Corporate Gifting Solutions in Chennai | Unmatched Price | Wide Range of Collections" />
      </Helmet>
      <Layout>
        <div className="pageHeader">
          <h1>OUR PRODUCTS</h1>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            {productList.map(product => (
              <div className="col-md-3">
                <Link
                  to={
                    "/" +
                    product
                      .toLowerCase()
                      .replace("&", "")
                      .replace(" ", "-")
                      .replace(" ", "")
                  }
                >
                  <div className="productDetailsCard">
                    <img
                      src={
                        "https://representgiftings.com/images/" +
                        product
                          .toLowerCase()
                          .replace("&", "")
                          .replace(" ", "-")
                          .replace(" ", "") +
                        ".jpg"
                      }
                      alt={product}
                    ></img>
                    <h5 className="productDetailstitle">{product}</h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  )
}
